html, body {
  background-color: #0A1929;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  height: 100vh;
  text-align: center;
  color: white;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;
  font-size: calc(10px + 2vmin);
}

noscript {
  margin: 20px auto 0 auto;
}

a {
  color: #61DAFB;
}

code {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}
